import axios from 'axios'
const apiUrl = process.env.VUE_APP_API + "classestoexams/byschool/"


export const state = {ClassesExams:[]};

export const getters = {
    
    ClassesExamsG(state) {
        return state
    },
}

export const actions = {
  
    getClassesExam({ dispatch, commit }, { id }) {

        axios.get(apiUrl+id).then(res=>{
            commit('CLEXAMMUT', res.data);
            
        }).catch((error)=>{
            dispatch('notification/error', error, { root: true });
        })
    },

};


export const mutations = {
    CLEXAMMUT(state, res) {
        state.ClassesExams = res;
    },
  
};
