import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader
  })
}

export const studentClassMethods = mapActions('studentsclass', ['getStudentClass'])

export const schoolsMethods = mapActions('schools', ['getSchools'])

export const examsMethods = mapActions('exam', ['getExam'])

export const classesMethods = mapActions('classes', ['getClasses'])

export const classesexamsMethods = mapActions('classesexams', ['getClassesExam'])

export const ClassesDaysMethods = mapActions('classesdays', ['getClassesDays'])

export const ResultsMethods = mapActions('results', ['getResults'])

export const PointageMethods = mapActions('pointage', ['GetPointage'])

export const StudentPaymentsMethods = mapActions('studentpayments', ['getStudentPayments'])

export const paymentnotificationMethods = mapActions('paymentnotification', ['getPaymentNot'])


export const SessionsDatesMethods = mapActions('sessionsdates', ['getSessionsDates'])

export const authMethods = mapActions('auth', ['logIn', 'logOut', 'register', 'resetPassword'])

export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth', 'changeLeftSidebarType', 'changeTopbar', 'changeLoaderValue'])

export const authFackMethods = mapActions('authfack', ['login', 'registeruser', 'logout'])

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear'])

