import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    
    {
        path: '/profile',
        name: 'profile',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/profile/index')
    },

    {
        path: '/result',
        name: 'result',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/result/index')
    },

    {
        path: '/group',
        name: 'group',
      
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/group/index')
    },

    {
        path: '/classroutine',
        name: 'classroutine',
      
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/classroutine/index')
    },

    {
        path: '/attendance',
        name: 'attendance',
       
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/attendance/index')
    },

    {
        path: '/payment',
        name: 'payment',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/feepayment/index')
    },
    {
        path: '/invoice',
        name: 'invoice',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/feepayment/invoice')
    },
    {
        path: '/exam',
        name: 'exam',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/exam/index')
    },

    {
        path: '/notifications',
        name: 'notifications',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/notifications/index')
    },
    {
        path: '/onlinecourse',
        name: 'onlinecourse',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/onlinecourse/index')
    },
]
