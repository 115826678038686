import axios from 'axios'
const apiUrl = process.env.VUE_APP_API + "paymentnotification/paymentnotificationbystudent/"


export const state = {PaymentNotification:[]};

export const getters = {
    
    ResultsG(state) {
        return state
    },
}

export const actions = {
  
    getPaymentNot({ dispatch, commit }, { id }) {

        axios.get(apiUrl+id).then(res=>{
            commit('PayAMMUT', res.data);
        }).catch((error)=>{
            dispatch('notification/error', error, { root: true });
        })
    },

};


export const mutations = {
    PayAMMUT(state, res) {
        state.PaymentNotification = res;
    },
  
};
