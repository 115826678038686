import axios from 'axios'
const apiUrl = process.env.VUE_APP_API + "classes/classesbyschool/"


export const state = {Classes:[]};

export const getters = { 
    StdClasses(state) {
        return state
    },
}

export const actions = {
    getClasses({ dispatch, commit }, { id }) {

        axios.get(apiUrl+id).then(res=>{
            commit('ClassesMut', res.data);
        }).catch((error)=>{
            dispatch('notification/error', error, { root: true });
        })
    },
};


export const mutations = {
    ClassesMut(state, res) {
        state.Classes = res;
    },
  
};
