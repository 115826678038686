import axios from 'axios'

const apiUrlSD = process.env.VUE_APP_API + "sessionsdates/sessionsdatesbyschool/"


export const state = {SessionsDates:[]};

export const getters = {
    
    SessionsDatesGet(state) {
        return state
    },
}

export const actions = {
  
    getSessionsDates({ dispatch, commit }, { id }) {

        axios.get(apiUrlSD+id).then(res=>{
            commit('SDMUT', res.data);
           
        }).catch((error)=>{
            dispatch('notification/error', error, { root: true });
        })
    },

};


export const mutations = {
    SDMUT(state, res) {
        state.SessionsDates = res;
    },
  
};
