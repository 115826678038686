//calendardates

import axios from 'axios'
const apiUrlCD = process.env.VUE_APP_API + "ClassesDays/classesdaysbyschool/"


export const state = {CalendarDates:[]};

export const getters = {
    
    CalendarDatesGet(state) {
        return state
    },
}

export const actions = {
  
    getCalendarDates({ dispatch, commit }, { id }) {

        axios.get(apiUrlCD+id).then(res=>{
            commit('CDMUT', res.data);
            
        }).catch((error)=>{
            dispatch('notification/error', error, { root: true });
        })
    },

};


export const mutations = {
    CDMUT(state, res) {
        state.CalendarDates = res;
    },
  
};
