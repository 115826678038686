import axios from 'axios'
const apiUrl = process.env.VUE_APP_API + "exams/byschool/"


export const state = {Exams:[]};

export const getters = {
    
    ExamsG(state) {
        return state
    },
}

export const actions = {
  
    getExam({ dispatch, commit }, { id }) {

        axios.get(apiUrl+id).then(res=>{
            commit('EXAMMUT', res.data);
        }).catch((error)=>{
            dispatch('notification/error', error, { root: true });
        })
    },

};


export const mutations = {
    EXAMMUT(state, res) {
        state.Exams = res;
    },
  
};
