import axios from 'axios'
const apiUrl = process.env.VUE_APP_API + "results/resultsbystudent/"


export const state = {Results:[]};

export const getters = {
    
    ResultsG(state) {
        return state
    },
}

export const actions = {
  
    getResults({ dispatch, commit }, { id }) {

        axios.get(apiUrl+id).then(res=>{
            commit('ResAMMUT', res.data);
        }).catch((error)=>{
            dispatch('notification/error', error, { root: true });
        })
    },

};


export const mutations = {
    ResAMMUT(state, res) {
        state.Results = res;
    },
  
};
